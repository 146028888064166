import React from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYelp } from '@fortawesome/free-brands-svg-icons';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="https://www.yelp.com/biz/sweet-tech-san-diego">
            <FontAwesomeIcon icon={faYelp} />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;