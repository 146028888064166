import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const CtaAlt = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <h1 className="mt-0">What are you waiting for?</h1>
          <p>See how we can make your tech work for you with a free consultation.</p>
          <ul className="cta-checks">
            <li className="reveal-from-bottom" data-reveal-delay="0.6">
              <svg height="0.75rem" width="0.75rem" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
              </svg>
              <span>Free Consultation</span>
            </li>
            <li className="reveal-from-bottom" data-reveal-delay="0.8">
              <svg height="0.75rem" width="0.75rem" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
              </svg>
              No Risk
            </li>
            <li className="reveal-from-bottom mb-12" data-reveal-delay="1.0">
              <svg height="0.75rem" width="0.75rem" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
              </svg>
              No Commitment
            </li>
          </ul>
          <div className="cta-entry">
            {/* <Input type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your Contact E-Mail">
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input>
            <Button tag="a" color="dark" href="#" className="m-0">
              Send
            </Button> */}
            <Button tag="a" color="dark" href="mailto:grant@sweettechsystems.com">
              Contact Us
            </Button>
          </div>
          
        </div>
      </div>
    </section>
  );
}

CtaAlt.propTypes = propTypes;
CtaAlt.defaultProps = defaultProps;

export default CtaAlt;